import { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { graphService } from '../../services/graph-service';
import { Rtp } from '../../interfaces/Graph';
import { useRtp } from '../Rtp';
import { getMsalInstance } from '../../utils/get-msal-instance';

interface RTPSelectorProps {
  entrypoint: string;
  onChange: (value: string) => void;
  onLoadRtps: (rtps: Rtp[]) => void;
}

const RtpSelector: React.FC<RTPSelectorProps> = ({ entrypoint, onLoadRtps, onChange }) => {
  const [selectedOption, setSelectedOption] = useState<string>(entrypoint);
  const { rtps } = useRtp();

  useEffect(() => {
    const init = async () => {
      const msalInstance = getMsalInstance();

      try {
        const account = msalInstance.getActiveAccount();
        const res = await graphService.getRtps(account?.idTokenClaims?.roles || []);
        onLoadRtps(res);
      } catch (error) {
        console.error(error);
      }
      setSelectedOption(entrypoint);
    };

    init();
  }, [entrypoint, onLoadRtps]);

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedOption(event.target.value);
    onChange(event.target.value);
  }

  return (
    <div className={styles.background}>
      <div className={styles.card}>
        <h3>Select RTP</h3>
        <hr className={styles.hr} />
        <select name="" id="" value={selectedOption} onChange={handleChange} className={styles.select}>
          <option value={''}>-</option>
          {rtps.map((rtp) => (
            <option key={rtp.name} value={rtp.name}>
              {rtp.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default RtpSelector;
