import { useState } from 'react';
import styles from './styles.module.css';
import { PolicyDataStructure } from '../../interfaces/Rtp';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Graph from './Graph';

// eslint-disable-next-line
interface ImpactCardProps {
  policies: Array<PolicyDataStructure>;
}

type FilterType = 'Upstream RTPs' | 'Downstream RTPs';

const ImpactCard = ({ rtpEntryPoint }: { rtpEntryPoint: string }) => {
  const [filter, setFilter] = useState<FilterType>('Downstream RTPs');

  return (
    <div className={styles.card}>
      <h3>Other Impacts</h3>
      <hr className={styles.hr} />
      <div className={styles.filter}>
        <span className={styles.view}>VIEW:</span>
        <RadioGroup
          row
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFilter(event.target.value as FilterType);
          }}
        >
          <FormControlLabel
            value="Upstream RTPs"
            control={<Radio color="default" size="small" />}
            label="Upstream RTPs"
          />
          <FormControlLabel
            value="Downstream RTPs"
            control={<Radio color="default" size="small" checked={filter === 'Downstream RTPs'} />}
            label="Downstream RTPs"
          />
        </RadioGroup>
      </div>
      <Graph rtpEntryPoint={rtpEntryPoint} isUpstream={filter === 'Upstream RTPs'} />
    </div>
  );
};

export default ImpactCard;
